import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import * as styles from "../styles/templates/buy.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AddItem, UpdateItem } from "../Redux/actions/CartActions";
import { ToggleCart } from "../Redux/actions/ToggleCart";
import BreadCrumb from "../components/BreadCrumb";
import { VIEW_ITEM } from "../events";

function BuyProduct({ data }) {
  const product = data.strapiProduct;
  const selectRef = useRef();
  //Redux Cart
  const cart = useSelector((state) => state.cart);
  const operations = useSelector((state) => state.operations);
  const [productChanged, setProductChanged] = useState(true);
  const isBrowser = typeof window !== "undefined";
  const dispatch = useDispatch();
  var addItem = true; //Check if whether to update or add item
  //Check if the product is already in the cart
  const FilterCartProduct =
    isBrowser &&
    cart.CartProducts.filter((prod) => prod?.id === product.strapiId);

  var initialState = product.minimumQuantity;
  if (cart.CartProducts.length > 0 && FilterCartProduct.length > 0) {
    //if product exists in cart
    addItem = false;
    initialState = FilterCartProduct[0].quantity;
  }

  const numberOfSelections = [...new Array(50)];
  const incrementsOfSelctions = 1;

  const [quantity, setQuantity] = useState(initialState);

  const SubmitButton = () => {
    //If operating is false then disable the button or else check for changes
    return (
      <input
        className={
          operations.operating
            ? productChanged
              ? styles.submitButton
              : styles.disabledButton
            : styles.disabledButton
        }
        value={productChanged ? `Submit` : `No Changes`}
        disabled={!productChanged}
        onClick={(event) => {
          handleSubmit(event);
        }}
      />
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (addItem) {

      //Check if item needs to be updaed or added
      let info = {
        id: product.strapiId,
        name: product.name,
        img: getImage(product.img),
        slug: product.slug,
        quantity: quantity,
        guests: quantity * product.oneUnitServes,
        unit: product.UnitOfMeasurement,
        price: parseInt((product.pricePerUnit * quantity).toFixed(2)),
      };
      dispatch(AddItem(info));
      dispatch(ToggleCart());
    } else {
      let info = {
        id: product.strapiId,
        name: product.name,
        newQuantity: quantity,
        newPrice: parseInt((product.pricePerUnit * quantity).toFixed(2)),
      };
      dispatch(UpdateItem(info));
      dispatch(ToggleCart());
    }
  };

  const handleChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };
  useEffect(() => {
    if (
      FilterCartProduct.length > 0 &&
      quantity === FilterCartProduct[0].quantity
    ) {
      setProductChanged(false);
    } else {
      setProductChanged(true);
    }
  }, [quantity, FilterCartProduct]);
  useEffect(() => {
    //Send that product has been viewed to google analytics
    window.analytics.logEvent(VIEW_ITEM, {
      item_id: `${data.strapiProduct.strapiId}`,
      item_name: data.strapiProduct.name,
      value_per_unit: data.strapiProduct.pricePerUnit,
    });
  }, []);
  return (
    <Layout title={product.name} description={product.description}>
      <BreadCrumb text="All Products" to="/products" />

      <div className={styles.container}>
        <GatsbyImage
          image={getImage(product.img)}
          className={styles.img}
          alt={product.name}
        />
        <div className={styles.info}>
          <h1 className={styles.cardTitle}>{product.name}</h1>
          <p
            style={{
              padding: "1rem",
              maxWidth: "85ch",
              lineHeight: "1.6",
              whiteSpace: "pre-wrap",
            }}
          >
            {product.largeDescription}
          </p>
          <p>
            MRP:
            <b style={{ color: "red", fontFamily: "sans-serif,Arial" }}>
              {" "}
              ₹{product.pricePerUnit * quantity}
            </b>
          </p>
          <div style={{ margin: "1rem 0" }}>
            <form className={styles.productAddForm}>
              <div>
                <label style={{ fontSize: "1.6rem" }} htmlFor="weight">
                  <b>Quantity: </b>
                </label>
                <select
                  onChange={(e) => handleChange(e)}
                  ref={selectRef}
                  name="weight"
                  id="weight"
                  value={quantity}
                >
                  {numberOfSelections.map((X, index) => {
                    const value =
                      product.minimumQuantity + incrementsOfSelctions * index;
                    return (
                      <option key={index} value={`${value}`}>
                        {value}
                        {product.UnitOfMeasurement}
                      </option>
                    );
                  })}
                </select>
              </div>
              <p style={{ fontSize: "1.4rem", color: "#ffffffb8" }}>
                ( Selected quantity serves: {product.oneUnitServes * quantity} )
              </p>
              {!operations.operating && (
                <p
                  style={{
                    padding: "1rem",
                    maxWidth: "85ch",
                    lineHeight: "1.6",
                    whiteSpace: "pre-wrap",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  WE ARE NOT OPERATING TODAY! <br />
                  <br />{" "}
                  <Link
                    style={{
                      color: "red",
                      background: "white",
                      padding: ".3rem",
                    }}
                    to="/contact"
                  >
                    Contact us
                  </Link>{" "}
                  directly for further enquiries.
                </p>
              )}
              <SubmitButton />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export const query = graphql`
  query ProductQuery($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      strapiId
      name
      largeDescription
      minimumQuantity
      oneUnitServes
      pricePerUnit
      description
      UnitOfMeasurement
      slug
      img {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;

export default BuyProduct;
