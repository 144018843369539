import React from "react";
import * as styles from "../styles/breadcrumb.module.css";
import { Link } from "gatsby";

function BreadCrumb({ text, to }) {
  return (
    <div className={styles.container}>
      <Link to={to}>
        <div className={styles.back}>
          <div></div>
          <div></div>
          <div></div>
          <p>{text}</p>
        </div>
      </Link>
    </div>
  );
}

export default BreadCrumb;
